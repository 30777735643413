// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;

var subsiteHijackMainNav = ['heroes'];
var subsiteHijackMainLogoClickDestination = ['heroes'];

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Custom JQuery
// Change text in newsletter sign up button
$('.footerBox.NewsletterSign-up .groupSignUpBtn').text('Sign up');

// Subsite Heroes - Logo
$('.heroes h1.subsiteTitle').appendTo('.headerContent').wrap('<a class="subsiteLogo" href="/heroes"></a>');
